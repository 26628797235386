import { __spreadArray } from "tslib";
import { find, includes, map } from 'lodash';
import { createSelector } from 'reselect';
import { ROUTE_PREFIX_CATEGORIES } from '../../constants/route-prefix';
import { getMixedIds, getMixedIdsMap } from '../../services';
import { getInternalLinkRoute } from '../../services/get-internal-link-route';
import { secondParam, thirdParam } from '../../services/param-selectors';
export var getCategories = function (state) { return state.categories; };
export var getCategoriesSlice = createSelector([getCategories, secondParam], function (categories, size) { return categories.slice(0, size); });
export var getCategoryIds = createSelector([getCategories], function (categories) {
    return getMixedIds(categories || []);
});
export var getCategoriesMap = createSelector([getCategories], function (categories) {
    return getMixedIdsMap(categories);
});
export var getCategoryCount = function (state) { return getCategories(state).length; };
var getCategorySlug = function (state, categorySlug) { return categorySlug; };
export var getCategory = function (state, id) { return getCategoriesMap(state)[id]; };
export var getMainCategory = function (state, post) {
    var _a;
    if (post === void 0) { post = {}; }
    return (_a = post.mainCategory) !== null && _a !== void 0 ? _a : resolveMainCategory(state, post);
};
export var getMainCategoryDisplayLabel = getMainCategoryField('label', 'menuLabel');
export var getMainCategorySeoLabel = getMainCategoryField('title', 'label');
export var getMainCategoryLink = function (state, post, topology) {
    if (post === void 0) { post = {}; }
    if (topology === void 0) { topology = {}; }
    if (post.mainCategory) {
        return post.mainCategory.link;
    }
    var mainCategory = resolveMainCategory(state, post);
    if (mainCategory) {
        return getInternalLinkRoute({
            topology: topology,
            to: "/".concat(ROUTE_PREFIX_CATEGORIES, "/").concat(mainCategory.slug),
            shouldNavigateToFeed: true,
        });
    }
};
export var getCategoryBySlug = createSelector([getCategories, getCategorySlug], function (categories, categorySlug) {
    return find(categories, function (category) {
        return includes(category.slugs, categorySlug) ||
            includes(category.slugs, "".concat(categorySlug, "/"));
    });
});
export var getHighestRank = createSelector([getCategories], function (categories) {
    return Math.max.apply(Math, __spreadArray([-1], map(categories, function (category) { return category.rank; }), false));
});
export var getCategoryByTranslationSlug = createSelector([getCategories, secondParam, thirdParam], function (categories, translationSlug, language) {
    return categories.find(function (category) {
        return category.language === language &&
            category.translations.find(function (categoryTranslation) {
                return categoryTranslation.url.path.indexOf(translationSlug) > -1;
            });
    });
});
function resolveMainCategory(state, post) {
    if (post === void 0) { post = {}; }
    var mainCategoryId = post.categoryIds && post.categoryIds[0];
    debugger;
    if (mainCategoryId) {
        return getCategory(state, mainCategoryId);
    }
}
function getMainCategoryField(field, legacyField) {
    return function (state, post) {
        if (post === void 0) { post = {}; }
        if (post.mainCategory) {
            return post.mainCategory[field];
        }
        var mainCategory = resolveMainCategory(state, post);
        if (mainCategory) {
            return mainCategory[legacyField];
        }
    };
}
