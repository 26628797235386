import { get } from 'lodash';
import { DEFAULT_POST_PAGE_FONT_SIZE_MOBILE, DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE, DEFAULT_POST_HEADER_THREE_SIZE_MOBILE, DEFAULT_POST_QUOTES_SIZE_MOBILE, } from '../../constants/post-page';
import { POST_TEXT_COLOR, LINK_AND_HASHTAG_COLOR, CARD_BACKGROUND_COLOR, BUTTON_COLOR, POST_PAGE_FONT_PATH, POST_PAGE_FONT_SIZE_MOBILE_PATH, POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH, POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH, POST_QUOTES_FONT_SIZE_MOBILE_PATH, POST_HEADER_FOUR_FONT_SIZE_MOBILE_PATH, POST_HEADER_FIVE_FONT_SIZE_MOBILE_PATH, POST_HEADER_SIX_FONT_SIZE_MOBILE_PATH, POST_HEADER_TWO_FONT, POST_HEADER_THREE_FONT, POST_HEADER_FOUR_FONT, POST_HEADER_FIVE_FONT, POST_HEADER_SIX_FONT, } from '../../constants/wix-params';
import { getSiteColor, setColorOpacity } from '../../services/colors';
export function getMobileStyles(appSettings, isPostPageEnabled) {
    var mobileFontSize = isPostPageEnabled
        ? get(appSettings, POST_PAGE_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_PAGE_FONT_SIZE_MOBILE)
        : DEFAULT_POST_PAGE_FONT_SIZE_MOBILE;
    var mobileHeaderTwoFontSize = get(appSettings, POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE);
    var mobileHeaderThreeFontSize = get(appSettings, POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_HEADER_THREE_SIZE_MOBILE);
    var mobileHeaderFourFontSize = get(appSettings, POST_HEADER_FOUR_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_HEADER_THREE_SIZE_MOBILE);
    var mobileHeaderFiveFontSize = get(appSettings, POST_HEADER_FIVE_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_HEADER_THREE_SIZE_MOBILE);
    var mobileHeaderSixFontSize = get(appSettings, POST_HEADER_SIX_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_HEADER_THREE_SIZE_MOBILE);
    var mobileQuotesFontSize = get(appSettings, POST_QUOTES_FONT_SIZE_MOBILE_PATH, DEFAULT_POST_QUOTES_SIZE_MOBILE);
    return {
        mobileFontSize: mobileFontSize,
        mobileHeaderTwoFontSize: mobileHeaderTwoFontSize,
        mobileHeaderThreeFontSize: mobileHeaderThreeFontSize,
        mobileHeaderFourFontSize: mobileHeaderFourFontSize,
        mobileHeaderFiveFontSize: mobileHeaderFiveFontSize,
        mobileHeaderSixFontSize: mobileHeaderSixFontSize,
        mobileQuotesFontSize: mobileQuotesFontSize,
    };
}
export function getStyleObject(appSettings, _a) {
    var isPostPageEnabled = _a.isPostPageEnabled;
    var textColor = getSiteColor(appSettings, {
        wixParam: POST_TEXT_COLOR,
        presetFallback: 'color-5',
        colorFallback: '#000',
    });
    var opaqueBackgroundColor = getSiteColor(appSettings, {
        wixParam: CARD_BACKGROUND_COLOR,
        presetFallback: 'color-1',
        colorFallback: '#fff',
        opacity: 1,
    });
    var dividerColor = setColorOpacity(textColor, 0.2);
    var activeDividerColor = setColorOpacity(textColor, 0.5);
    var highlightedColor = getSiteColor(appSettings, {
        wixParam: BUTTON_COLOR,
        presetFallback: 'color-8',
        colorFallback: '#3899ec',
        opacity: 1,
    });
    var linkHashtagColor = getSiteColor(appSettings, {
        wixParam: LINK_AND_HASHTAG_COLOR,
        presetFallback: 'color-8',
        colorFallback: '#44C2BC',
    });
    var _b = getMobileStyles(appSettings, isPostPageEnabled), mobileFontSize = _b.mobileFontSize, mobileHeaderTwoFontSize = _b.mobileHeaderTwoFontSize, mobileHeaderThreeFontSize = _b.mobileHeaderThreeFontSize, mobileQuotesFontSize = _b.mobileQuotesFontSize, mobileHeaderFourFontSize = _b.mobileHeaderFourFontSize, mobileHeaderFiveFontSize = _b.mobileHeaderFiveFontSize, mobileHeaderSixFontSize = _b.mobileHeaderSixFontSize;
    var textFontSize = get(appSettings, POST_PAGE_FONT_PATH, {
        size: 18,
    }).size;
    var headerTwoFontSize = get(appSettings, POST_HEADER_TWO_FONT, {
        size: Math.min(textFontSize + 10, 38),
    }).size;
    var headerThreeFontSize = get(appSettings, POST_HEADER_THREE_FONT, { size: Math.min(textFontSize + 4, 32) }).size; // prettier-ignore
    var headerFourFontSize = get(appSettings, POST_HEADER_FOUR_FONT, { size: Math.min(textFontSize + 2, 30) }).size; // prettier-ignore
    var headerFiveFontSize = get(appSettings, POST_HEADER_FIVE_FONT, {
        size: Math.min(textFontSize, 28),
    }).size;
    var headerSixFontSize = get(appSettings, POST_HEADER_SIX_FONT, {
        size: Math.min(textFontSize - 2, 24),
    }).size;
    return {
        textColor: textColor,
        opaqueBackgroundColor: opaqueBackgroundColor,
        dividerColor: dividerColor,
        activeDividerColor: activeDividerColor,
        highlightedColor: highlightedColor,
        linkHashtagColor: linkHashtagColor,
        mobileFontSize: mobileFontSize,
        headerTwoFontSize: headerTwoFontSize,
        headerThreeFontSize: headerThreeFontSize,
        headerFourFontSize: headerFourFontSize,
        headerFiveFontSize: headerFiveFontSize,
        headerSixFontSize: headerSixFontSize,
        mobileHeaderTwoFontSize: mobileHeaderTwoFontSize,
        mobileHeaderThreeFontSize: mobileHeaderThreeFontSize,
        mobileHeaderFourFontSize: mobileHeaderFourFontSize,
        mobileHeaderFiveFontSize: mobileHeaderFiveFontSize,
        mobileHeaderSixFontSize: mobileHeaderSixFontSize,
        mobileQuotesFontSize: mobileQuotesFontSize,
    };
}
export default function getStyle(appSettings, _a) {
    var isPostPageEnabled = _a.isPostPageEnabled, isBusinessManager = _a.isBusinessManager;
    var styleObj = getStyleObject(appSettings, {
        isPostPageEnabled: isPostPageEnabled,
        isBusinessManager: isBusinessManager,
    });
    var textColor = styleObj.textColor, opaqueBackgroundColor = styleObj.opaqueBackgroundColor, dividerColor = styleObj.dividerColor, activeDividerColor = styleObj.activeDividerColor, highlightedColor = styleObj.highlightedColor, linkHashtagColor = styleObj.linkHashtagColor, mobileFontSize = styleObj.mobileFontSize, headerTwoFontSize = styleObj.headerTwoFontSize, headerThreeFontSize = styleObj.headerThreeFontSize, headerFourFontSize = styleObj.headerFourFontSize, headerFiveFontSize = styleObj.headerFiveFontSize, headerSixFontSize = styleObj.headerSixFontSize, mobileHeaderTwoFontSize = styleObj.mobileHeaderTwoFontSize, mobileHeaderThreeFontSize = styleObj.mobileHeaderThreeFontSize, mobileHeaderFourFontSize = styleObj.mobileHeaderFourFontSize, mobileHeaderFiveFontSize = styleObj.mobileHeaderFiveFontSize, mobileHeaderSixFontSize = styleObj.mobileHeaderSixFontSize, mobileQuotesFontSize = styleObj.mobileQuotesFontSize;
    return {
        '--rce-text-color': textColor,
        '--rce-opaque-background-color': opaqueBackgroundColor,
        '--rce-divider-color': dividerColor,
        '--rce-active-divider-color': activeDividerColor,
        '--rce-highlighted-color': highlightedColor,
        '--rce-link-hashtag-color': linkHashtagColor,
        '--rce-mobile-font-size': "".concat(mobileFontSize, "px"),
        '--rce-header-two-font-size': "".concat(headerTwoFontSize, "px"),
        '--rce-header-three-font-size': "".concat(headerThreeFontSize, "px"),
        '--rce-header-four-font-size': "".concat(headerFourFontSize, "px"),
        '--rce-header-five-font-size': "".concat(headerFiveFontSize, "px"),
        '--rce-header-six-font-size': "".concat(headerSixFontSize, "px"),
        '--rce-mobile-header-two-font-size': "".concat(mobileHeaderTwoFontSize, "px"),
        '--rce-mobile-header-three-font-size': "".concat(mobileHeaderThreeFontSize, "px"),
        '--rce-mobile-header-four-font-size': "".concat(mobileHeaderFourFontSize, "px"),
        '--rce-mobile-header-five-font-size': "".concat(mobileHeaderFiveFontSize, "px"),
        '--rce-mobile-header-six-font-size': "".concat(mobileHeaderSixFontSize, "px"),
        '--rce-mobile-quotes-font-size': "".concat(mobileQuotesFontSize, "px"),
    };
}
