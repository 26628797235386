import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { getPostCover as _getPostCover, getPostVideoEmbedCover as _getPostVideoEmbedCover, } from '@wix/communities-universal/dist/src/services/get-post-cover';
import { POST_SEO_DESCRIPTION_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';
import { limitLength, getVideoUrl, resizeThumbnail } from '../services';
export { getPostImageCover } from '@wix/communities-universal/dist/src/services/get-post-cover';
var getHelpers = function (imageHost, videoHost) { return ({
    resizeThumbnail: function (pathname, width, height) {
        return resizeThumbnail({ pathname: pathname, width: width, height: height, imageHost: imageHost });
    },
    getVideoUrl: function (pathname) { return getVideoUrl({ pathname: pathname, videoHost: videoHost }); },
}); };
export var getPostCover = function (post, imageHost, videoHost) {
    var _a;
    return imageHost && videoHost
        ? _getPostCover(post, getHelpers(imageHost, videoHost))
        : (_a = post === null || post === void 0 ? void 0 : post.coverImage) !== null && _a !== void 0 ? _a : {};
};
export var getPostVideoEmbedCover = function (post, imageHost, videoHost) {
    return _getPostVideoEmbedCover(post, getHelpers(imageHost, videoHost));
};
export var getPostExcerpt = function (_a) {
    var content = _a.content, excerpt = _a.excerpt;
    return limitLength(excerpt || getContentText(content), POST_SEO_DESCRIPTION_MAXLENGTH);
};
