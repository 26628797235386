import { flowRight } from 'lodash';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';

const getFontClassName = (property) => `blog-post-${property}-font`;
const getFontClassNameWithStyle = (property) =>
  `blog-post-${property}-style-font`;

const mapRuntimeToProps = (state) => ({
  titleFontClassName: getFontClassName('title'),
  contentFontClassName: getFontClassName(
    getIsPostPageBundle(state) ? 'page' : 'description',
  ),
  contentFontClassNameWithStyle: getFontClassNameWithStyle('description'),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithFontClassName')
);
