import { get, last, isEmpty, toNumber } from 'lodash';
import { createSelector } from 'reselect';

import {
  MAX_POSTS_PER_PAGE,
  MAX_POSTS_PER_PAGE_FULL_POST,
  MAX_POSTS_PER_PAGE_MOBILE,
  PAGINATION_TYPE_INFINITE_SCROLL,
  PAGINATION_TYPE_MULTIPLE_PAGES,
  POSTS_PER_PAGE,
  getPaginationTypeParamPath,
  getPaginationPageSizeParamPath,
  isMultiplePagesSupported,
  getLastPage,
  buildPaginationRequestParams,
} from '@wix/communities-blog-client-common';
import { getIsInitialRoute } from '../router/router-selectors';
import { secondParam } from '../services/param-selectors';
import {
  getIsMobile,
  isSeo,
  isSite,
} from '../store/basic-params/basic-params-selectors';
import { getAppSettingsValue } from './app-settings-base-selectors';
import { isFullPostLayout, getLayoutType } from './layout-selectors';

import { getSection } from './section-selectors';

const getPagination = (state) => state.pagination;

export const getEntityPagination = createSelector(
  [getPagination, secondParam],
  (pagination, entityType) => get(pagination, entityType, {}),
);

export const getEntitiesByPage = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.entitiesByPage,
);

export const getEntityCount = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.entityCount,
);

export const getCurrentPage = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.currentPage || 1,
);

export const getCurrentPageEntities = createSelector(
  [getEntitiesByPage, getCurrentPage],
  (entitiesByPage, page) => get(entitiesByPage, page),
);

export const getIsPageLoaded = createSelector(
  [getCurrentPageEntities],
  (entitiesByPage) => entitiesByPage !== undefined,
);

export const getCollectionPageSize = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.pageSize,
);

export const getTotalPages = createSelector(
  [getEntityCount, getCollectionPageSize],
  (entityCount, pageSize) => entityCount && Math.ceil(entityCount / pageSize),
);

export const getHasMoreThanOnePage = createSelector(
  [getTotalPages],
  (totalPages) => Number(totalPages) > 1,
);

export const getCursor = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.cursor,
);

export const getLastPageIndex = createSelector(
  [getEntitiesByPage],
  (entitiesByPage) => toNumber(last(Object.keys(entitiesByPage).sort())) || 1,
);

export const getIsCurrentPageMissingEntities = createSelector(
  [
    getCurrentPageEntities,
    getCollectionPageSize,
    getTotalPages,
    getCurrentPage,
    getEntityCount,
  ],
  (entities, pageSize, totalPages, currentPage, entityCount) => {
    if (!entities) {
      return true;
    }
    if (currentPage === totalPages) {
      const expectedEntityCountInLastPage =
        entityCount - (totalPages - 1) * pageSize;
      return entities.length < expectedEntityCountInLastPage;
    } else {
      return entities.length < pageSize;
    }
  },
);

export const getIsCurrentPageEmpty = createSelector(
  [getCurrentPageEntities],
  isEmpty,
);

export const getPageSize = (
  state,
  { overrideSettingsPageSize, section } = {},
) => {
  let maxPageSize = MAX_POSTS_PER_PAGE;
  if (
    getIsMobile(state) &&
    getPaginationType(state, section) === PAGINATION_TYPE_INFINITE_SCROLL &&
    !isSeo(state)
  ) {
    maxPageSize = MAX_POSTS_PER_PAGE_MOBILE;
  } else if (isFullPostLayout(state, section)) {
    maxPageSize = MAX_POSTS_PER_PAGE_FULL_POST;
  }
  return Math.min(
    overrideSettingsPageSize || getPaginationPageSize(state, section),
    maxPageSize,
  );
};

export const getPaginationType = (state, _section) => {
  const section = _section || getSection(state, true);
  const paginationType = getAppSettingsValue({
    state,
    key: getPaginationTypeParamPath(section),
    fallback: PAGINATION_TYPE_INFINITE_SCROLL,
  });
  if (
    paginationType === PAGINATION_TYPE_MULTIPLE_PAGES &&
    isMultiplePagesSupported(getLayoutType(state, section))
  ) {
    return paginationType;
  }
  return PAGINATION_TYPE_INFINITE_SCROLL;
};

export const getPaginationPageSize = (state, _section) => {
  const section = _section || getSection(state, true);
  return getAppSettingsValue({
    state,
    key: getPaginationPageSizeParamPath(section),
    fallback: POSTS_PER_PAGE,
  });
};

export const getShowPagination = (state, section) => {
  const paginationType = getPaginationType(state, section);
  return paginationType === PAGINATION_TYPE_MULTIPLE_PAGES;
};

export const getIsValidPage = (state, section) => {
  const paginationType = getPaginationType(state, section);
  const page =
    paginationType === PAGINATION_TYPE_MULTIPLE_PAGES
      ? getCurrentPage(state, 'posts')
      : getLastPageIndex(state, 'posts');

  const pageSize = getPaginationPageSize(state, section);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);
  return page === 1 || (page > 1 && page <= lastPage);
};

export const getIsLazyPaginationEnabled = (state, section) => {
  section = section ?? getSection(state, true);
  return (
    !isSeo(state) &&
    isSite(state) &&
    getIsMobile(state) &&
    getShowPagination(state, section) &&
    getPageSize(state, { section }) > MAX_POSTS_PER_PAGE_MOBILE
  );
};

export const getLazyPaginationParams = ({ state, page, section }) => {
  if (
    !getIsLazyPaginationEnabled(state, section) ||
    !getIsInitialRoute(state)
  ) {
    return;
  }

  const pageSize = getPageSize(state, { section });
  const paginationParams = buildPaginationRequestParams(page, pageSize);
  paginationParams.size = MAX_POSTS_PER_PAGE_MOBILE;
  return paginationParams;
};
