import { initCustomElement } from '@wix/image';

/**
 * Required for widgets rendered in an iframe. This is usually the
 * case when OOI components are rendered in a site editor
 **/
export const withWixImageElement = (WrappedComponent) => {
  initCustomElement();

  return WrappedComponent;
};
