import { createSelector } from 'reselect';

import { getPostMap } from './post-selectors';

const getRecentPostsIds = (state, postId) => state.recentPostsIds[postId];

export const getRecentPosts = createSelector(
  getPostMap,
  getRecentPostsIds,
  (posts, recentPostsIds) => (recentPostsIds || []).map((id) => posts[id]),
);
