import { get } from 'lodash';
export var getTPASettings = function (state) { return state.tpaSettings; };
export var getTPASettingsData = function (state) {
    return get(getTPASettings(state), 'settings', {});
};
export var getTPASettingsTranslations = function (state, language) {
    return get(getTPASettings(state), ['translations', language], null);
};
export var getAllPostsLabelTranslation = function (state, language) {
    return get(getTPASettings(state), ['translations', language, 'allPosts'], null);
};
var getTPACommentsSettings = function (state) {
    return get(getTPASettings(state), ['settings', 'comments'], {});
};
export var hasTPACommentsSettings = function (state) {
    return typeof getTPASettingsData(state).comments !== 'undefined';
};
export var getTPASettingsIsCommentsEnabled = function (state) {
    return get(getTPACommentsSettings(state), 'enabled', true);
};
export var getTPASettingsIsAnonymousComments = function (state) {
    return get(getTPACommentsSettings(state), 'anonymousEnabled', false);
};
export var getTPASettingsIsRatingsEnabled = function (state) {
    return get(getTPACommentsSettings(state), 'ratingsEnabled', false);
};
export var getTPASettingsIsMandatoryRatingsEnabled = function (state) {
    return get(getTPACommentsSettings(state), 'ratingsMandatory', false);
};
